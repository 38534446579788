// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blocker-jsx": () => import("./../../../src/templates/blocker.jsx" /* webpackChunkName: "component---src-templates-blocker-jsx" */),
  "component---src-templates-book-index-jsx": () => import("./../../../src/templates/book/index.jsx" /* webpackChunkName: "component---src-templates-book-index-jsx" */),
  "component---src-templates-class-jsx": () => import("./../../../src/templates/class.jsx" /* webpackChunkName: "component---src-templates-class-jsx" */),
  "component---src-templates-download-jsx": () => import("./../../../src/templates/download.jsx" /* webpackChunkName: "component---src-templates-download-jsx" */)
}

